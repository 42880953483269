import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import Offres  from  "../components/Wispeek/Offres"
import ContentTwo  from  "../components/Wispeek/ContentTwo"
import { Helmet } from "react-helmet"
import ProduitWispeek from "../components/Wispeek/ProduitWispeek"

const OffreWispeek = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Offre Wispeek</title>
        <meta name="description" content="Spécialiste de la gestion des remontées d’informations, mettez vous en conformité Loi Sapin 2, harcèlement, corruption… une solution clé en main qui s’adapte à votre organisation rapidement. Equipe basée à Rennes, Bretagne." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Offre Wispeek" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Offres" 
          parentPageUrl="/offres"
          activePageText="Offre Wispeek" 
      />
      <ProduitWispeek />
      <Offres />
      <ContentTwo />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default OffreWispeek