import React from 'react'
import {Link} from 'gatsby'

const ProduitWispeek = () => {
    return (
        <section className="pricing-area pt-100 pb-70 bg-e3fbff">
            <div className="container">
                <div className="section-title">
                    <h2>Mise en conformité en 48H<br />dès 99€</h2>
                    <p style={{fontSize:'20px'}}>Serveurs hébergés en France - Accessible 24/7<br />Pack communication</p>
                </div>
                <div className='row'>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Dispositif de signalement</h3>
                            </div>
                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Signalement en mode confidentiel ou anonyme</li>
                                <li><i className='bx bxs-badge-check'></i> Gestion sécurisée des enquêtes</li>
                                <li><i className='bx bxs-badge-check'></i> Récépissé automatique</li>
                                <li><i className='bx bxs-badge-check'></i> Conforme aux règlementations en vigueur</li>
                            </ul>
                            <div>
                                <Link to="https://shop.wispeek.com/licence/37-dispositif-de-signalement.html" target='_blank' className="default-btn qvct">
                                    <i className="flaticon-tick"></i> 
                                    Souscrire en ligne 
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Solution qualité</h3>
                            </div>
                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Formulaires digitaux (N-C, audit, etc.)</li>
                                <li><i className='bx bxs-badge-check'></i> Plan d’action intégré</li>
                                <li><i className='bx bxs-badge-check'></i> Tableau de bord interactif (indicateurs, statistiques, etc.)</li>
                                <li><i className='bx bxs-badge-check'></i> Centre de ressources documentaires</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="https://shop.wispeek.com/licence/34-69-wispeek.html" target="_blank" className="default-btn qvct">
                                    <i className="flaticon-tick"></i> 
                                    Souscrire en ligne 
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProduitWispeek